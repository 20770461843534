import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';

interface ConnectorRecipientConfigProps extends RouteComponentProps {}

function ConnectorRecipientConfig(props: ConnectorRecipientConfigProps) {
  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.settings.registerOnRemoveHandler(saveEvent => {
      console.log('Removing connector');

      /**
       * Future improvements to clean up removing recipients could be executed here.
       */
    });
  }, []);
  return (
    <div className="alert alert-warning">
      {
        'Note: The DisruptOps Recipient that utilizes this Connector will no longer be operational. Please remove this Recipient before removing Connector.'
      }
    </div>
  );
}

export default ConnectorRecipientConfig;
