import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import * as microsoftTeams from '@microsoft/teams-js';

interface ConnectorSimpleEndProps extends RouteComponentProps {}

/**
 * Page OAuth2.0 redirects to.
 *
 * should define how it expects tokens from auth flow.
 * Stores tokens in local storage
 *
 * microsoftTeams.authentication.notifySuccess(key);
 *
 */
function ConnectorSimpleEnd(props: ConnectorSimpleEndProps) {
  const {
    location: { search }
  } = props;

  useEffect(() => {
    microsoftTeams.initialize();

    const urlSearchParams = new URLSearchParams(search);
    const error = urlSearchParams.has('error') ? urlSearchParams.get('error') : null;
    const accessToken = urlSearchParams.has('access_token') ? urlSearchParams.get('access_token') : null;
    const idToken = urlSearchParams.has('id_token') ? urlSearchParams.get('id_token') : null;
    const refreshToken = urlSearchParams.has('id_token') ? urlSearchParams.get('refresh_token') : null;
    const wsToken = urlSearchParams.has('ws_token') ? urlSearchParams.get('ws_token') : null;
    const stateFromParams = urlSearchParams.has('state') ? urlSearchParams.get('state') : null;

    if (error) {
      microsoftTeams.authentication.notifyFailure(error);
    } else if (accessToken) {
      const expectedState = localStorage.getItem('simple.state');

      if (expectedState !== stateFromParams) {
        microsoftTeams.authentication.notifyFailure('State does not match');
      } else {
        // SUCCESS
        const key = 'tokens.key';
        localStorage.setItem(key, JSON.stringify({ accessToken, idToken, refreshToken, wsToken }));

        microsoftTeams.authentication.notifySuccess(key);
      }
    }
  }, [search]);

  return null;
}

export default ConnectorSimpleEnd;
