export default {
  primary: '#673ab7',
  primaryLight: '#9575CD',
  primaryDark: '#311B92',

  primary20: '#e0d1fe',
  primary50: '#EDE7F6',
  primary100: '#D1C4E9',
  primary200: '#B39DDB',
  primary300: '#9575CD',
  primary400: '#7E57C2',
  primary500: '#673AB7',
  primary600: '#5E35B1',
  primary700: '#512DA8',
  primary800: '#4527A0',
  primary900: '#311B92',

  // secondary: '#E1733A',
  secondary: '#ffc107',
  secondaryLight: '#ffa000',

  grey: '#A7A7A7',
  greyLight: '#D1D1D1',
  greyDark: '#787878',

  grey50: '#FAFAFA',
  grey100: '#F5F5F5',
  grey200: '#EEEEEE',
  grey250: '#E0DCE7',
  grey300: '#E0E0E0',
  grey350: '#D2D2D2',
  grey400: '#BDBDBD',
  grey500: '#9E9E9E',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#212121',

  danger: '#FF9800',

  warning: '#FF9800',
  success: '#87d068',
  error: '#f5222d',

  scheduled: '#42a5f5'
};
