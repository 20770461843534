import React from 'react';

interface BackgroundProps {
  backgroundColor?: string;
  children: React.ReactNode | React.ReactNodeArray;
}

export function Background(props: BackgroundProps) {
  const { children, backgroundColor = '#fafafa' } = props;

  return (
    <div
      className="background"
      style={{
        backgroundColor,
        minHeight: '100vh'
      }}
    >
      {children}
    </div>
  );
}
