import React, { ReactNode } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import AuthenticatedContext, { AuthenticatedContextProps } from './AuthenticatedContext';

import { logout } from 'utils/authentication';

const CURRENT_USER_QUERY = gql`
  query UserIdentity {
    identity @client {
      user_id
      user_pool_id
      email
    }
  }
`;

export interface UserIdentity {
  user_id: string,
  user_pool_id: string,
  email: string
}
interface UserIdentityData {
  identity: UserIdentity
}

interface AuthenticatorProps {
  children: (authenticatedContext: AuthenticatedContextProps) => ReactNode;
}

function Authenticator(props: AuthenticatorProps) {
  const { loading, data, refetch } = useQuery<UserIdentityData>(CURRENT_USER_QUERY, { fetchPolicy: 'network-only' });
  const { children } = props;
  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );

  const identity = data && data.identity && data.identity.user_id ? data.identity : null;

  const authenticatedContext: AuthenticatedContextProps = {
    identity,
    refetch: refetch,
    logout
  };

  return (
    <AuthenticatedContext.Provider value={authenticatedContext}>
      {children(authenticatedContext)}
    </AuthenticatedContext.Provider>
  );
}

export default Authenticator;
