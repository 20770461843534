import { FieldMetaProps } from 'formik';

export function getValidBootstrapClassFromFieldMeta(meta: FieldMetaProps<any>): string {
  const { error, touched } = meta;

  if (error && touched) return 'is-invalid';
  if (touched) return 'is-valid';

  return '';
}
