import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as microsoftTeams from '@microsoft/teams-js';
import { UserIdentity } from 'components/Authenticator';
import { DOPS_URL } from 'constants/runtimeConfig';
/**
 * ConnectorConfiguration
 *
 * - Adding a Connector to a ms-teams channel will create a dops.Recipient.
 * - That recipient can now be used within Notifications as well as within Ops
 * - A Recipient can be used for 1+n Notifications.
 *  - Maybe we should show a list of Notifications that this recipient is tied to? Could get complex...
 * - Alternatively, the user could add the connector, creating the recipient, but not want a dops.Notification
 * - A user should be able to update a connector
 * - A user should be able to remove a connector from a channel.
 *
 * "The connector was created by using this account:"
 *
 * QUESTIONS:
 * - what does the offboarding process here look like when a user removes a connector from a channel?
 *
 * - would you like to use this Connector to receive Notifications?
 *    - Yes: this will render the Notification form as well as a Recipient
 *    - No: Only create recipient to be used within DisruptOps
 */

interface ConnectorConfigurationProps {
  user: UserIdentity;
}

function ConnectorConfiguration(props: ConnectorConfigurationProps) {
  const { user } = props;
  return <CreateMSTeamsRecipient user={user} />;
}

// COMPONENT: CreateMSTeamsRecipient
const SAVE_RECIPIENT = gql`
  mutation SaveRecipient($recipient: SaveRecipientInput!) {
    saveRecipient(recipient: $recipient) {
      id
      name
      config
      type
    }
  }
`;

interface CreateMSTeamsRecipientProps {
  user: UserIdentity;
}

function CreateMSTeamsRecipient(props: CreateMSTeamsRecipientProps) {
  const { user } = props;
  const [saveRecipient, results] = useMutation(SAVE_RECIPIENT);
  const savedRecipient = (results.data && results.data.saveRecipient) || null;

  if (savedRecipient) {
    let editLink = '';

    // DOPS_URL will be truthy when env is dev or prod
    if (DOPS_URL) {
      editLink = `${DOPS_URL}/organization-settings/recipients/${savedRecipient.id}/edit`;
    }
    return (
      <>
        <p>A recipient has successfully been created. Click "Save" to complete Connector.</p>
        {editLink && (
          <a href={editLink} target="_blank">
            View your recipient in DisruptOps
          </a>
        )}
      </>
    );
  }

  return (
    <div>
      <p>
        {
          'Before saving this Connector, you must create a DisruptOps "Recipient". This Recipient will be able to be used in multiple Notifications and Ops once created.'
        }
      </p>
      <button
        disabled={results.loading}
        className="btn btn-primary"
        onClick={async () => {
          const config = await buildRecipientConfig();
          const type = 'MS_TEAMS';
          const recipient = {
            type,
            config,
            name: `MS Teams: Created by ${user.user_id} on ${new Date().toISOString()}`
          };
          const result = await saveRecipient({ variables: { recipient } });
          const newRecipient = result.data.saveRecipient;

          microsoftTeams.settings.setValidityState(true);

          microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            try {
              microsoftTeams.settings.setSettings({
                entityId: newRecipient.id,
                // websiteUrl: `app.disruptops.com/organization-settings/notifications/recipients`, // THIS NEEDS TO BE ADDED TO ALLOWED ORIGINS
                websiteUrl: `${window.location.origin}`,
                contentUrl: `${window.location.origin}/connector/config/${newRecipient.id}`
              });

              console.log(`newRecipient.id: ${newRecipient.id}`);
              console.log(`websiteUrl${window.location.origin}`);
              console.log(`contentUrl: ${window.location.origin}/connector/config/${newRecipient.id}`);

              saveEvent.notifySuccess();
            } catch (err) {
              console.log({ err });
            }
          });
        }}
      >
        {'Create Recipient'}
      </button>
    </div>
  );
}

async function buildRecipientConfig() {
  const [context, settings] = await Promise.all([asyncGetContext(), asyncGetSettings()]);
  const anySettings: any = settings;

  return {
    context,
    webhookUrl: anySettings.webhookUrl
  };
}

function asyncGetContext() {
  return new Promise((res, rej) => {
    try {
      microsoftTeams.getContext((context) => {
        res(context);
      });
    } catch (err) {
      rej(err);
    }
  });
}

function asyncGetSettings() {
  return new Promise((res, rej) => {
    try {
      microsoftTeams.settings.getSettings((settings) => {
        res(settings);
      });
    } catch (err) {
      rej(err);
    }
  });
}

export enum EventType {
  issueCreated = 'issueCreated',
  actionTaken = 'actionTaken',
  issuesCreated = 'issuesCreated'
}

export interface EventTypeOption {
  label: string;
  key: EventType;
}

export default ConnectorConfiguration;
