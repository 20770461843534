import React, { ReactNode } from 'react';

import { useField, FieldInputProps, FieldMetaProps, FieldHelperProps } from 'formik';

interface FormikFieldRenderProps {
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  helpers: FieldHelperProps<any>;
}

export interface FormikFieldProps {
  name: string;
  label?: string;
  hideLabel?: boolean; // didn't want to touch label before asking..
  extra?: string;
  children: (fieldProps: FormikFieldRenderProps) => ReactNode;
  wrapInFormItem?: boolean; // default to true
  labelToolTipText?: string;
  classes?: {};
}

/**
 * FormikField
 * component wraps common inputs with labels and error messages
 */
function FormikField(props: FormikFieldProps) {
  const { name, label, hideLabel = false, children } = props;
  const [field, meta, helpers] = useField({
    name
  });
  const renderProps: FormikFieldRenderProps = {
    field,
    meta,
    helpers
  };
  const { error, touched } = meta;

  return (
    <div className="form-group">
      {!hideLabel && label && (
        <label htmlFor={name} className="col-form-label">
          {label}
        </label>
      )}

      {children(renderProps)}

      {error && touched && <span className="help-block">{error}</span>}
    </div>
  );
}

export default FormikField;
