import { setContext } from 'apollo-link-context';
import { getIdToken } from 'utils/authentication';

const authLink = setContext((request, previousContext) => {
  return new Promise(async (resolve, reject) => {
    let idToken;

    try {
      idToken = await getIdToken();
    } catch (e) {
      return resolve();
    }

    if (!idToken) {
      previousContext.cache.writeData({
        id: 'Identity',
        data: {
          __typename: 'Identity'
        }
      });
      return resolve();
    }

    if (idToken) {
      const authorization = `Bearer ${idToken.getJwtToken()}`;

      resolve({
        headers: {
          authorization
        }
      });
    }
  });
});

export default authLink;
