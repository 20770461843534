import React, { useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import * as microsoftTeams from '@microsoft/teams-js';
import Authenticator from 'components/Authenticator';
import AuthenticatedContext from 'components/AuthenticatedContext';
import { createSession } from 'utils/authentication';
import ConnectorConfiguration from './ConnectorConfiguration';

interface ConnectorSimpleProps extends RouteComponentProps {}

function ConnectorConfigurationAuthenticated(props: ConnectorSimpleProps) {
  useEffect(() => {
    microsoftTeams.initialize();
  }, []);

  return (
    <Authenticator>
      {(authContext) => {
        if (authContext && authContext.identity) return <ConnectorConfiguration user={authContext.identity} />;

        // if not authenticated return prompt to login.
        return <LoginPrompt />;
      }}
    </Authenticator>
  );
}

// component LoginPrompt
interface LoginPromptProps {}

function LoginPrompt(props: LoginPromptProps) {
  const authContext = useContext(AuthenticatedContext);
  if (!authContext) return null;
  const { refetch } = authContext;

  return (
    <>
      <p>This connector sends notifications (eventually "and Op Decisions") as configured activity happens in your DisruptOps account.</p>
      <p>To add this connector, sign in to your DisruptOps account.</p>
      {/* <!-- Login button --> */}
      <button
        onClick={login(() => {
          // callback after login is successful.

          refetch({});
        })}
        className="btn btn-primary"
      >
        Login to DisruptOps
      </button>
    </>
  );
}

// MS TEAMS WILL OPEN MODAL TO START OAUTH2 FLOW
function login(postSuccessCallback: () => any) {
  return () => {
    microsoftTeams.authentication.authenticate({
      url: `${window.location.origin}/connector/login-start`,
      width: 600,
      height: 535,
      successCallback: async (key) => {
        if (key) {
          const stringifiedTokens = localStorage.getItem(key);
          localStorage.removeItem(key);

          if (stringifiedTokens) {
            const tokens = JSON.parse(stringifiedTokens);

            await createSession({
              id_token: tokens.idToken
            });
            postSuccessCallback();
          }
        }
      }
    });
  };
}
export default ConnectorConfigurationAuthenticated;
