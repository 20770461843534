import { UserIdentity } from './Authenticator';
import React from 'react';

export interface AuthenticatedContextProps {
  identity: UserIdentity | null; // cognito
  refetch: (variables: any) => Promise<any>;
  logout: () => void;
}

export const AuthenticatedContext = React.createContext<AuthenticatedContextProps | undefined>(undefined);

export default AuthenticatedContext;
