import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ConnectorSimple, ConnectorLoginStart, ConnectorLoginEnd, ConnectorRecipientConfig } from './Connector';

import Authorize from './Authorize';

interface RoutesProps {}

function Routes(props: RoutesProps) {
  return (
    <Switch>
      <Route path="/connector/simple" component={ConnectorSimple} />
      <Route path="/connector/login-start" component={ConnectorLoginStart} />
      <Route path="/connector/login-end" component={ConnectorLoginEnd} />

      {/* Used to edit/remove an existing connector. */}
      <Route path="/connector/config/:recipientId" component={ConnectorRecipientConfig} />

      {/**
       * This route is meant to be the start of a generic Oauth2 Login Route.
       * The Argument might be made that this could become a stand alone auth/login application.
       * The user can come here from a "Connector", "Bot", or even another integration service (other than ms teams);
       */}
      <Route path="/authorize" component={Authorize} />
    </Switch>
  );
}

export default Routes;
