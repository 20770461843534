import Auth, { CognitoUser } from '@aws-amplify/auth';
import cache from './graphql/cache';
import graphqlClient from './graphql/client';
import { COGNITO_CLIENT_ID, COGNITO_USER_POOL_ID } from 'constants/runtimeConfig';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

// const CognitoUser = Cognito.CognitoUser as any;
// https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
Auth.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: COGNITO_CLIENT_ID
    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    // oauth: {
    //     domain: 'your_cognito_domain',
    //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //     redirectSignIn: 'http://localhost:3000/',
    //     redirectSignOut: 'http://localhost:3000/',
    //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // }
  }
});

export async function getIdToken() {
  return (await Auth.currentSession()).getIdToken();
}

interface Credentials {
  username: string;
  password: string;
}

// signin with username password
export async function login(credentials: Credentials) {
  //https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in
  const { username, password } = credentials;

  await Auth.signIn(username, password);

  const currentSession: CognitoUserSession = await Auth.currentSession();
  const id_token: any = currentSession.getIdToken;
  const access_token: any = currentSession.getAccessToken;
  const refresh_token: any = currentSession.getRefreshToken;
  await Promise.all([id_token, access_token, refresh_token]);

  const trinity_data = undefined;
  return { data: { id_token, access_token, refresh_token, trinity_data } };
}

interface TokenBundle {
  id_token: string;
}

export async function createSession(tokenBundle: TokenBundle) {
  return new Promise((resolve, reject) => {
    const { id_token } = tokenBundle;

    if (!id_token) return reject(new Error('Could not create session'));

    localStorage.setItem('access_token', id_token);
    localStorage.setItem('id_token', id_token);

    resolve({});
  });
}

export function getIdentity(): any {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (!data) return resolve(createEmptyIdentity());
        const { username, pool, attributes } = data;
        const { email } = attributes;
        const { userPoolId } = pool;

        const identity = {
          user_id: username,
          email: email,
          user_pool_id: userPoolId,
          __typename: 'Identity'
        };

        resolve(identity);
      })
      .catch((err) => {
        console.log({ err });
        resolve(createEmptyIdentity());
      });
  }).then((identity) => {
    //This is structured like this so that we can update the cache no matter the results (empty or not)
    return new Promise((resolve, reject) => {
      updateIdentityInCache(identity);
      resolve(identity);
    });
  });
}

export async function logout() {
  localStorage.removeItem('ws_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.setItem('redirectAfterLogin', window.location.pathname);

  updateIdentityInCache(createEmptyIdentity());
  await Auth.signOut();

  // We have to call both of these because clearStore
  // does not trigger the `onResetStore` callback.
  // Calling resetStore alone will refetch all the queries
  // Which is not what you want when signing out.
  graphqlClient.clearStore();
  graphqlClient.resetStore();

  // redirect back to the login page
  window.location.href = '/login';
}

function updateIdentityInCache(identity: any) {
  if (identity.custom) delete identity.custom;
  cache.writeData({
    id: 'Identity',
    data: identity || null
  });
}

export function createEmptyIdentity() {
  return {
    user_id: null,
    email: null,
    user_pool_id: null,
    __typename: 'Identity'
  };
}

const ignoredRedirectPaths: string[] = ['/login'];

export async function handleUnauthorized() {
  const path = window.location.pathname;

  let idToken: any;

  try {
    idToken = (await Auth.currentSession()).getIdToken();
  } catch (e) {
    console.error(e);
    return;
  }

  if (!ignoredRedirectPaths.includes(path) && idToken) {
    if (!path?.includes('logout')) {
      localStorage.setItem('redirectAfterLogin', path);
    }

    await logout();
  }
}
