import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { SignInParams } from 'utils/api';
import { login } from 'utils/authentication';
import InputFormiked from 'components/formik/InputFormiked';
import { canFormSubmit } from 'utils/formik';
import { Background } from 'components/layout';
import Card from 'components/Card';
import theme from 'constants/theme';
import CardTitle from 'components/CardTitle';

// this component should stand outside the realm of ms-teams. Could be a stand alone react app?
// should not have to import ms-teams sdk here.
interface AuthorizeProps extends RouteComponentProps {}

// really this is Unauthenticated
function Authorize(props: AuthorizeProps) {
  return (
    <Switch>
      <Route path="/authorize/login" component={Login} />
      {/* Other routes could be forgot password, etc... */}
    </Switch>
  );
}

// COMPONENT: Login
interface LoginProps extends RouteComponentProps {}

function Login(props: LoginProps) {
  const {
    location: { search }
  } = props;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);

    const redirectUrl = urlSearchParams.has('redirect_url') ? urlSearchParams.get('redirect_url') : null;
    if (redirectUrl) localStorage.setItem('redirect_url', redirectUrl);

    const state = urlSearchParams.has('state') ? urlSearchParams.get('state') : null;
    if (state) localStorage.setItem('state', state);
  }, [search]);

  return (
    <Background backgroundColor={theme.primary}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          paddingTop: '64px'
        }}
      >
        <Card
          style={{
            width: '340px'
          }}
        >
          {/* <Logo /> */}
          <CardTitle>{'Login'}</CardTitle>
          <LoginForm
            onSubmit={async ({ params, onSuccess, onFailure }) => {
              try {
                const response = await login(params);
                const accessToken = response.data.access_token || null;
                const idToken = response.data.id_token || null;
                const refreshToken = response.data.refresh_token || null;
                const wsToken = response.data.trinity_data || null;

                onSuccess();

                // buildRedirectURL
                const redirectUrlFromStorage = localStorage.getItem('redirect_url');
                if (redirectUrlFromStorage) {
                  const url = new URL(redirectUrlFromStorage);
                  const state = localStorage.getItem('state');
                  if (state) url.searchParams.set('state', state);

                  if (accessToken) url.searchParams.set('access_token', accessToken);
                  if (idToken) url.searchParams.set('id_token', idToken);
                  if (refreshToken) url.searchParams.set('refresh_token', refreshToken);
                  if (wsToken) url.searchParams.set('ws_token', wsToken);

                  // return to redirect
                  window.location.assign(url.href);
                }
              } catch (err) {
                console.log({ err });
                onFailure();
              }
            }}
          />
        </Card>
      </div>
    </Background>
  );
}

// COMPONENT: LoginForm
const formSchema = Yup.object().shape({
  username: Yup.string().required('Username required'),
  password: Yup.string().required('Password required')
});

interface LoginFormProps {
  onSubmit: (onSubmitInterface: { params: SignInParams; onSuccess: () => void; onFailure: () => void }) => Promise<any>;
}

function LoginForm(props: LoginFormProps) {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values: any, actions) => {
        setErrorMessage(null);

        props.onSubmit({
          params: values,
          onSuccess: () => {
            actions.resetForm();
          },
          onFailure: (error?: string) => {
            setErrorMessage(
              'There was an error Logging in. Please double check your username and password and try again.'
            );
            actions.setSubmitting(false);
          }
        });
      }}
      validationSchema={formSchema}
    >
      {formRenderProps => {
        const { isSubmitting } = formRenderProps;
        const canSubmit = canFormSubmit({
          formikProps: formRenderProps,
          schema: formSchema
        });

        return (
          <form onSubmit={formRenderProps.handleSubmit}>
            <InputFormiked name="username" label="Username" />
            <InputFormiked name="password" label="Password" type="password" />
            <button type="submit" className="btn btn-primary" disabled={!canSubmit || isSubmitting}>
              Login
            </button>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </form>
        );
      }}
    </Formik>
  );
}

export default Authorize;
