import { InMemoryCache } from 'apollo-cache-inmemory';

function dataIdFromObject(data: any): string {
  // const typename = data.__typename;
  // if (typename === 'ConfiguredOp') return data.configured_op_id;
  return data.id;
}

const cache = new InMemoryCache({ dataIdFromObject });

export default cache;
