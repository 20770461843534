import React, { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  header?: ReactNode;
  style?: React.CSSProperties;
}

function Card(props: CardProps) {
  const { children, header, style = {} } = props;

  return (
    <div className="card" style={style}>
      {header && <div className="card-header">{header}</div>}

      <div className="card-body">{children}</div>
    </div>
  );
}

export default Card;
