import React from 'react';
import FormikField from './FormikField';
import { getValidBootstrapClassFromFieldMeta } from './common';

interface InputFormikedProps {
  name: string;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;

  type?: string;

  // override classNames
  classes?: {
    input?: string;
  };
}

function InputFormiked(props: InputFormikedProps) {
  const { name, label, type, required } = props;

  return (
    <FormikField name={name} label={label}>
      {fieldRenderProps => {
        const {
          field: { value, onChange, onBlur },
          meta
        } = fieldRenderProps;

        const isValidClass = getValidBootstrapClassFromFieldMeta(meta);

        return (
          <input
            name={name}
            value={value || ''}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            className={`form-control ${isValidClass}`}
            required={required || false}
          />
        );
      }}
    </FormikField>
  );
}

export default InputFormiked;
