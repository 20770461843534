import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';

import * as microsoftTeams from '@microsoft/teams-js';
import Routes from './Routes';
import { client } from 'utils/graphql';

function App() {
  useEffect(() => {
    microsoftTeams.initialize();
  }, []);

  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes />
      </Router>
    </ApolloProvider>
  );
}

export default App;
