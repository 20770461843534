import { ApolloClient } from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { ApolloLink } from 'apollo-link';

import { APP_ENV } from 'constants/runtimeConfig';
import cache from './cache';
import { errorLink, authLink, httpLink } from './links';
import { resolvers, typeDefs, getDefaults } from './clientResolvers';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  link: errorLink.concat(link),
  cache,
  connectToDevTools: APP_ENV !== 'prod',
  defaultOptions,
  typeDefs,
  resolvers
});

// not sure why this is in a function?
function writeDefaults() {
  return cache.writeData({ data: getDefaults() });
}

writeDefaults();

client.onResetStore(() => Promise.resolve(writeDefaults()));

export default client;
