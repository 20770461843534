import { createEmptyIdentity, getIdentity } from '../authentication';

export const typeDefs = [
  `
  type Identity {
    user_id: ID
    user_pool_id: ID
    email: String
  }

  type Query {
    identity: Identity
  }
`
];

export const resolvers = {
  Query: {
    identity: async () => {
      const identity = await getIdentity();

      console.log({ identity });

      return identity;
    }
  }
};

const defaults = {
  identity: createEmptyIdentity()
};

export function getDefaults() {
  return { ...defaults };
}
