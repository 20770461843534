import { RouteComponentProps } from 'react-router';
import '@microsoft/teams-js';
import { v4 as uuidv4 } from 'uuid';

interface ConnectorLoginStartProps extends RouteComponentProps {}

function ConnectorLoginStart(props: ConnectorLoginStartProps) {
  const state = uuidv4();
  localStorage.setItem('simple.state', state);
  localStorage.removeItem('simple.error');

  const queryParams = {
    state,
    redirect_url: `${window.location.origin}/connector/login-end`
  };

  // could be separate app some day.
  let authorizeURL = `${window.location.origin}/authorize/login?${toQueryString(queryParams)}`;

  // seems like we could just return a <Redirect to={"..."} /> component? just following example for now.
  window.location.assign(authorizeURL);

  return null;
}

// FROM ms teams simple-start.hbs example
// Build query string from map of query parameter
function toQueryString(queryParams: { [key: string]: string }) {
  let encodedQueryParams: string[] = [];
  for (let key in queryParams) {
    encodedQueryParams.push(key + '=' + encodeURIComponent(queryParams[key]));
  }
  return encodedQueryParams.join('&');
}

export default ConnectorLoginStart;
