import React, { ReactNode } from 'react';

interface CardTitleProps {
  children: string | ReactNode;
}

function CardTitle(props: CardTitleProps) {
  const { children } = props;

  return <h5 className="card-title">{children}</h5>;
}

export default CardTitle;
