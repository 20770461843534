import { FormikProps } from 'formik';

// feels like this could be a hook inside Form Component or something...
export function canFormSubmit(params: {
  formikProps: FormikProps<any>;
  schema?: any;
  allowCleanSubmits?: boolean;
}): boolean {
  const { formikProps, schema = {}, allowCleanSubmits = false } = params;
  const { values, errors, dirty } = formikProps;

  if (Object.keys(values).length === 0 && Object.keys(schema.fields).length === 0) return true;

  if (Object.keys(errors).length > 0) return false;

  if (!dirty && !allowCleanSubmits) return false;

  return true;
}
